import React from 'react'

import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

const Title = styled.h2`
  color: ${props => props.color || "#ca2204"};
`
const Subtitle = styled.p`
  text-align: justify;
`

const Topbox = styled.p`
  background: #fac129;
  text-align: center;
  width: 100%;
  -moz-transform: skewX(0deg) skewY(-8deg);
  -webkit-transform: skewX(0deg) skewY(-8deg);
  -o-transform: skewX(0deg) skewY(-8deg);
  -ms-transform: skewX(0deg) skewY(-8deg);
  transform: skewX(0deg) skewY(-8deg);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 3px;
`

const Img = styled.img`
  -moz-transform: skewX(0deg) skewY(8deg);
  -webkit-transform: skewX(0deg) skewY(8deg);
  -o-transform: skewX(0deg) skewY(8deg);
  -ms-transform: skewX(0deg) skewY(8deg);
  transform: skewX(0deg) skewY(8deg);
`

const Infobox = ({ title, text, image, color }) =>
  <Flex flexWrap='wrap'>
    <Topbox width={1}>
      <Img style={{height: '36px'}} src={image} />
    </Topbox>
    <Box width={1} style={{textAlign: 'center'}}>
      <Title color={color}>{title}</Title>
    </Box>
    <Box width={1}>
      <Subtitle>{text}</Subtitle>
    </Box>
</Flex>

export default Infobox
