import React from 'react';

import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { DesktopOnly, MobileOnly } from './device'

import Img from 'gatsby-image'

const StyledImg = styled.img`
  width: 100%;
`

const Blockquote = styled.blockquote`
  font-size: 110%;
  font-style: italic;
`

const Text = ({ input: { primary: { quote, text, image } } }) =>
  <Flex flexWrap='wrap' alignItems='center'>
    <Box width={[1, 6/12, 5/12]} pr={[0, 3, 3]}>
      {image.localFile.childImageSharp ?
        <StyledImg as={Img} alt={image.alt} fluid={image.localFile.childImageSharp.fluid} />
        :
        <StyledImg alt={image.alt} src={image.url} />
        }
    </Box>
    <Box width={[1, 6/12, 7/12]} pl={[0, 3, 3]}>
      {quote.text && <Blockquote dangerouslySetInnerHTML={{ __html: quote.text }} />}
      <DesktopOnly dangerouslySetInnerHTML={{ __html: text.html }} />
    </Box>
    <MobileOnly dangerouslySetInnerHTML={{ __html: text.html }} />
  </Flex>

export default Text
