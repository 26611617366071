import React from 'react'

import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

const TopMargin = styled.div`
  padding-top: 2rem;
  margin-bottom: 2rem;
  background: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 3px;
`

const Title = styled.div`
  height: 2.5rem;
  color: ${props => props.color || "#ca2204" };
  font-size: .92rem;
  font-weight: 600;
  text-transform: uppercase;
  webkit-transform: skewX(0deg) skewY(-8deg);
  -o-transform: skewX(0deg) skewY(-8deg);
  -ms-transform: skewX(0deg) skewY(-8deg);
  transform: skewX(0deg) skewY(-8deg);
`

const Subtitle = styled.div`
  min-height: 4rem;
  font-size: .88rem;
  font-weight: bold;
`

const FixedImage = styled.img`
  height: 100px;
`

const Showcase = ({ input }) =>
  <TopMargin>
    <Flex flexWrap='wrap' justifyContent='space-around' alignItems='center' mb={2}>
      {input.items.map((item, index) =>
        <Box key={`${input.id}-${index}`} width={[1, null, 1/3]} pl={2} pr={2}>
          <Flex flexWrap='wrap' justifyContent='center' alignItems='center' style={{textAlign: 'center'}}>
            <Box width={1} pb={2}>
              <Title color={index % 2 === 1 ? '#f69922' : "#ca2204"}>{item.title.text}</Title>
            </Box>
            <Box width={1}>
              <FixedImage alt={item.image.alt} src={item.image.url} />
            </Box>
            <Box width={1} >
              <Subtitle dangerouslySetInnerHTML={{ __html: item.text.html }} />
            </Box>
          </Flex>
        </Box>
      )}
    </Flex>
    </TopMargin>

export default Showcase
