import React from 'react'

import { Hidden, Input, Textarea, Label, Button } from './form'

const label = {
  en: {
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    time: 'Preferred consultation time',
    message: 'Message',
    send: 'Send',
  },
  ru: {
    name: 'Имя',
    email: 'Электронная почта',
    phone: 'Телефон',
    time: 'Предпочитаемое время для консультации',
    message: 'Сообщение',
    send: 'Отправить',
  },
}

export default ({ lang='en' }) =>
  <form name={`contact${lang}`} netlify-honeypot="bot-field" method="POST" data-netlify="true">
    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
    <Input type="hidden" name="form-name" value={`contact${lang}`} />
    <Hidden>
      <label>Don’t fill this out if you're human: <Input name="bot-field" /></label>
    </Hidden>

    <Label for="name">{label[lang].name}<Input type="text" name="name" /></Label><br />
    <Label>{label[lang].email}<Input type="email" name="email" /></Label><br />
    <Label>{label[lang].phone}<Input type="tel" name="tel" /></Label><br />
    <Label>{label[lang].time}<Input type="text" name="time" /></Label><br />
    <Label>{label[lang].message}<Textarea maxlength="500" rows="5" cols="30" name="message" /></Label><br /><br />
    <Button type="submit">{label[lang].send}</Button>
  </form>
