import styled from 'styled-components'

export const Input = styled.input`
  display: block;
  border: 1px solid #6b6a83;
  border-radius: 4px;
  padding: .45rem .8rem;
`
export const Textarea = styled.textarea`
  display: block;
  border: 1px solid #6b6a83;
  border-radius: 4px;
  padding: .45rem .8rem;
  font-size: 75%;
`
export const Label = styled.label`
  //display: inline-block;
  font-weight: bold;
  font-size: 85%;
`
export const Legend = styled.legend`
  font-weight: bold;
  font-size: 85%;
`
export const Radiolabel = styled.label`
  font-size: 85%;
`

export const Button = styled.button`
  text-transform: uppercase;
  padding: .5rem 2rem .5rem;
  border: none;
  background-color: #fac129;
  color: #6b6a83;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 4px;
  &:disabled {
    background-color: #ddd;
    color: #6b6a83;
  }
`

export const Hidden = styled.div`
  display: none;
`
