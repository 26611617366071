import styled from 'styled-components'

// Using media queries. https://medium.com/@renatorib/tackling-responsive-elements-in-react-and-why-ive-created-react-sizes-f7c87e3f9e64
export const MobileOnly = styled.div`
  @media not all and (max-width: 639px) {
    display: none;
  }
`
export const DesktopOnly = styled.div`
  @media (max-width: 639px) {
    display: none;
  }
`
