import React from 'react'

import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

const Text = styled.div`
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    color: ${props => props.titleColor ? props.titleColor : "#f69922"};
  }
  text-align: justify;
`

const Topbox = styled.p`
  background: #fac129;
  text-align: center;
  width: 100%;
  -moz-transform: skewX(0deg) skewY(-8deg);
  -webkit-transform: skewX(0deg) skewY(-8deg);
  -o-transform: skewX(0deg) skewY(-8deg);
  -ms-transform: skewX(0deg) skewY(-8deg);
  transform: skewX(0deg) skewY(-8deg);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 3px;
`

const Img = styled.img`
  -moz-transform: skewX(0deg) skewY(8deg);
  -webkit-transform: skewX(0deg) skewY(8deg);
  -o-transform: skewX(0deg) skewY(8deg);
  -ms-transform: skewX(0deg) skewY(8deg);
  transform: skewX(0deg) skewY(8deg);
`

const Infobox = ({ input }) =>
  <Flex flexWrap='wrap'>
    {input.items.map((item, index) =>
      <Box key={`${input.id}-${index}`} width={[1, 1/2]} p={[0, 3]}>
        <Flex flexWrap='wrap'>
          <Topbox width={1}>
            <Img style={{height: '36px'}} alt={item.icon.alt} src={item.icon.url} />
          </Topbox>
          <Box width={1}>
            <Text titleColor={ index % 2 && '#ca2204'} dangerouslySetInnerHTML={{ __html: item.text.html }} />
          </Box>
        </Flex>
      </Box>
    )}
  </Flex>

export default Infobox
