import React from 'react'
import { DesktopOnly, MobileOnly } from './device'

const Map = ({ input }) =>
  <>
    <DesktopOnly>
      <iframe title="Practice on Mill Rd"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2445.5650892411677!2d0.1444081159010576!3d52.19677766788464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8707dd989b09f%3A0x625189284cc5abd2!2s8b+Romsey+Terrace%2C+Cambridge+CB1+3HN!5e0!3m2!1sen!2suk!4v1552038090602"
        width="600"
        height="450"
        frameBorder="0"
        style={{border:0}}
        allowFullScreen>
      </iframe>
    </DesktopOnly>
    <MobileOnly>
      <iframe title="Practice on Mill Rd"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2445.5650892411677!2d0.1444081159010576!3d52.19677766788464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8707dd989b09f%3A0x625189284cc5abd2!2s8b+Romsey+Terrace%2C+Cambridge+CB1+3HN!5e0!3m2!1sen!2suk!4v1552154708471"
        width="320"
        height="300"
        frameBorder="0"
        style={{border:0}}
        allowFullScreen></iframe>
    </MobileOnly>
  </>

export default Map;
