import React from 'react'

import styled from 'styled-components'

import PhotoAndTextAside from './PhotoAndTextAside'
import Text from './Text'
import InfoboxGroup from './InfoboxGroup'
import TextGroup from './TextGroup'
import SingleImage from './SingleImage'
import Showcase from './ShowcasePrismic'
import TextAndImageGroup from './AlternateTextAndImageGroup'

const StyledSlice = styled.div`
  h1, h2, h3, h4, h5, h6 {
    border-bottom: 1px solid rgba(107, 106, 131, 0.2);
    padding-bottom: 1rem;
  }
`

const SliceZone = ({ allSlices }) => {
  const slice = allSlices.map(s => {
    switch (s.slice_type) {
      // These are the API IDs of the slices
      case 'singleimage':
        return <SingleImage key={s.id} input={s} />
      case 'photo_and_text_aside':
        return <PhotoAndTextAside key={s.id} input={s} />
      case 'text':
        return <Text key={s.id} input={s} />
      case 'icon_and_text_group':
        return <InfoboxGroup key={s.id} input={s} />
      case 'text_group':
        return <TextGroup key={s.id} input={s} />
      case 'showcase':
        return <Showcase key={s.id} input={s} />
      case 'text_and_image_group':
        return <TextAndImageGroup key={s.id} input={s} />
      case 'react_component':
        const { props } = s.primary
        if (props === undefined || props.text === '') {
          return React.createFactory(require(`/${s.primary.component}`).default)({key: s.id})
        } else {
          return React.createFactory(require(`/${s.primary.component}`).default)({key: s.id, ...JSON.parse(props.text)})
        }
      default:
        return null
    }
  })
  return <StyledSlice>{slice}</StyledSlice>
}

export default SliceZone
