import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

import SliceZone from '../components/SliceZone'

// Inspired from https://github.com/LekoArts/gatsby-starter-prismic/blob/master/src/templates/post.jsx

const PrismicPage = ({ data: { prismicPage } }) => {
  const { data, lang } = prismicPage
  return (
    <Layout title={data.title.text} lang={lang}>
      <SliceZone allSlices={data.body} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      lang
      data {
        title {
          text
        }
        body {
          ... on PrismicPageBodyPhotoAndTextAside {
            slice_type
            id
            primary {
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              quote {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyIconAndTextGroup {
            slice_type
            id
            items {
              icon {
                url
                alt
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyTextGroup {
            slice_type
            id
            primary {
              title {
                text
              }
            }
            items {
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyShowcase {
            slice_type
            id
            items {
              title {
                text
              }
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 640) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyTextAndImageGroup {
            slice_type
            id
            items {
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 700) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyReactComponent {
            slice_type
            id
            primary {
              component
              props {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default PrismicPage
