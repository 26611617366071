import React from 'react'

import styled from 'styled-components'

import { Flex, Box } from '@rebass/grid'

const Title = styled.h2`
  color: rgb(107, 106, 131);
`

const numColumns = length => {
  if (length < 3) {
    return length
  } else {
    return 3
  }
}

const TextGroup = ({ input }) =>
  <div>
    <Title>{input.primary.title.text}</Title>
  <Flex flexWrap='wrap'>
    {input.items.map((item, index) =>
    <Box key={`${input.id}-${index}`} width={[1, 1/numColumns(input.items.length)]} p={[0, 1]}>
      <div dangerouslySetInnerHTML={{ __html: item.text.html }} />
    </Box>
    )}
  </Flex>
    </div>

export default TextGroup
