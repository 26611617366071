import React from 'react'

import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import Img from 'gatsby-image'

const Text = styled.div`
  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.titleColor || "#f69922"};
  }
  strong {
    color: rgb(59, 162, 220);
  }
`

const StyledImg = styled.img`
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 3px;
`
//<StyledImg as={Img} alt='icon' fluid={item.image.fluid} />

const AlternateTextAndImageGroup = ({ input }) =>
  input.items.map((item, index) =>
    <Flex key={`${input.id}-${index}`} flexWrap='wrap' alignItems='center'>
      <Box width={[1, 5/12]} pr={4} pb={4} pt={4} order={[1, 1 + (1+index) % 2]}>
        {item.image.localFile ?
          <StyledImg as={Img} alt={item.image.alt} fluid={item.image.localFile.childImageSharp.fluid} />
        :
          <StyledImg alt={item.image.alt} src={item.image.url} />
        }
      </Box>
      <Box width={[1, 7/12]} pr={4} pb={4} pt={4} order={[2, 1+index%2]}>
        <Text titleColor={ index % 2 && '#ca2204'} dangerouslySetInnerHTML={{ __html: item.text.html }} />
      </Box>
    </Flex>
  )

export default AlternateTextAndImageGroup
