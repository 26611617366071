import React from 'react'
import styled from 'styled-components'

const slots = [
  {
    time: '5pm',
    date: '14/02',
    price: 45,
  },
  {
    time: '6pm',
    date: '14/02',
    price: 50,
  },
  {
    time: '7pm',
    date: '14/02',
    price: 50,
  },
]

const Topbox = styled.span`
  background: #fac129;
  text-align: center;
  padding: 1rem;
  margin: 1rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 3px;
`

export default ({ lang='en' }) =>
  <div>
    <h2>Choose time</h2>
    {slots.map(s =>
      <Topbox>{s.date} at {s.time} (£{s.price})</Topbox>
    )}
  </div>
