import React from 'react';

import styled from 'styled-components'

const StyledText = styled.div`
  h1, h2, h3, h4, h5, h6 {
    //color: ${props => props.titleColor || "#f69922"};
    color: rgb(107, 106, 131);
  }
  strong {
    color: rgb(59, 162, 220);
  }
`

const Text = ({ input }) => <StyledText dangerouslySetInnerHTML={{ __html: input.primary.text.html }} />

export default Text
