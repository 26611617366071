import React, { Component } from 'react'

import { DateTime } from 'luxon'

import styled from 'styled-components'

export const Button = styled.button`
  text-transform: uppercase;
  padding: .5rem 2rem .5rem;
  border: 2px solid #fac129;
  background-color: ${props => props.selected ? "#fac129" : "white"};
  color: #888;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 4px;
  margin: .5rem;
`

const SlotButton = styled(Button)`
  text-transform: none;
`


class BookingWidget extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedSlot: undefined,
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    const slots = props.slots.sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
    const slot = slots.length > 0 ? slots[0] : undefined
    if (slot && current_state.selectedSlot === undefined) {
      props.updateValue && props.updateValue(slot.id)
      return {
        selectedSlot: slot,
      }
    }
    return null
  }

  handleButtonClicked = (param) => (e) => {
    this.setState({
      selectedSlot: param
    })
    this.props.updateValue && this.props.updateValue(param.id)
  }

  _no_slot_message = lang => ({
    'en': 'No slot available currently. Contact hello@duraj.space for enquiries.',
    'ru': 'В настоящее время нет доступных слотов. Свяжитесь с hello@duraj.space для получения дополнительной информации.',
  }[lang])

  render() {

    const uiDateTime = (isoDateTime) => {
      const dt = DateTime.fromISO(isoDateTime)
      return dt.toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false })
    }

    const slots = this.props.slots.sort((a, b) => new Date(a.datetime) - new Date(b.datetime))

    return (
      <div>
        {slots.length > 0 ?
            slots.map(s =>
                  <SlotButton
                    key={`bookingwidget_${s.id}`}
                    type='button'
                    onClick={this.handleButtonClicked(s)}
                    selected={this.state.selectedSlot === s}
                  >
                    <div>
                      {uiDateTime(s.datetime)}
                    </div>
                  </SlotButton>
                )
            :
            <p><strong>{this._no_slot_message(this.props.lang)}</strong></p>
        }
      </div>
    )
  }
}

export default BookingWidget